<template>
  <div>
    <ion-grid>
      <ion-row class="ion-align-items-center mb-4 underline">
        <ion-col>
          <ion-label class="fw-600 fs-3 text-gray-700">
            {{ $t('apply_available_credit') }}
          </ion-label>
        </ion-col>
        <ion-col size="auto">
          <ion-icon
            @click="$emit('closeModal')"
            size="large"
            class="f-icon"
            :icon="closeCircleOutline"
          ></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <div class="box px-2 py-2">
          <ion-label position="stacked" class="text-gray-700 mt-2">{{ $t('input_amount') }}</ion-label>
          <ion-input
            type="number"
            :clear-input="true"
            placeholder="0.00"
            name="amount"
            v-model="initialCreditValue"
            inputmode="decimal"
          ></ion-input>
        </div>
        <ion-label class="f-orange ml-4 mt-2 mb-4 fw-500"
          >{{ $t('remaining_credits') }} {{ priceFormatter(currencySymbol, afterCreditChangeValue) }}
        </ion-label>
      </ion-row>
    </ion-grid>
    <div class="d-flex align-center justify-center mt-5">
      <ion-button class="primary" @click="onApplyCreditBalance">
        <ion-label class="text-capitalize fw-600">{{ $t('apply') }}</ion-label>
      </ion-button>
    </div>
  </div>
</template>
<script>
import { priceFormatter } from '@/utils/';
import { closeCircleOutline } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'inputCredits',
  emits: ['onUseCreditBalance', 'closeModal'],
  props: {
    initialCredit: {
      // cust credit balance - total paid invoices on its initial state
      type: Number,
      default: 0
    },
    remainingCredits: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const initialCreditValue = ref(props.initialCredit === 0 ? '' : props.initialCredit.toFixed(2));
    const afterCreditChangeValue = ref(props.remainingCredits - props.initialCredit);
    const onApplyCreditBalance = () => {
      emit('onUseCreditBalance', +initialCreditValue.value);
      emit('closeModal');
    };
    watch(initialCreditValue, (value) => {
      if (value === '') {
        afterCreditChangeValue.value = props.remainingCredits;
      }
      afterCreditChangeValue.value = +props.remainingCredits - +value;
    });
    return {
      closeCircleOutline,
      initialCreditValue,
      priceFormatter,
      onApplyCreditBalance,
      afterCreditChangeValue
    };
  }
});
</script>
<style lang="scss" scoped>
ion-item {
  width: 95%;
  border: 1px solid rgb(139, 139, 139);
  border-radius: 10px;
  margin-bottom: 1rem;
}
.align-top {
  margin-top: -10px !important;
}
.box {
  width: 95%;
  border: 1px solid rgb(139, 139, 139);
  border-radius: 10px;
  margin-bottom: 1rem;
  margin: 0 auto;
}
.underline {
  border-bottom: 1px solid #343434;
}

.f-icon {
  color: gray;
}
.f-orange {
  color: #eb8c31;
}
ion-button {
  --border-radius: 10px;
  width: 95%;
  height: 44px;
}
ion-textarea {
  --placeholder-color: var(--ion-color-text-gray-600);
}
</style>
