<template>
  <div class="px-4">
    <ion-card class="mx-0 mt-4 px-2 py-2 card-invoice-account">
      <ion-grid>
        <ion-row>
          <ion-col size="6">
            <ion-row class="ion-align-items-center">
              <div class="box">
                <ion-icon class="i-help" :icon="helpCircleOutline"></ion-icon>
                <ion-text>{{ $t('available_credits') }}</ion-text>
              </div>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-text class="vertical-space f-bold f-green">{{
                priceFormatter(currencySymbol, availableCreditAmount)
              }}</ion-text>
            </ion-row>
          </ion-col>
          <ion-col :size="6">
            <ion-row class="ion-align-items-center">
              <div class="box">
                <ion-icon color="danger" class="f-icon" :icon="alertCircleOutline"></ion-icon>
                <ion-text color="danger">{{
                  $t('unpaid_invoices', { unpaidInvoiceAmount: totalUnpaidInvoices })
                }}</ion-text>
              </div>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-text color="dark" class="vertical-space f-bold">{{
                priceFormatter(currencySymbol, unpaidAmount)
              }}</ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</template>
<script>
import { priceFormatter } from '@/utils/';
import { alertCircleOutline, helpCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BannerInfo',
  props: {
    totalUnpaidInvoices: {
      type: Number,
      default: 0
    },
    availableCreditAmount: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    unpaidAmount: {
      type: Number,
      default: 0
    }
  },
  setup() {
    return {
      priceFormatter,
      alertCircleOutline,
      helpCircleOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  align-items: center;
  ion-label {
    margin-left: 5px;
  }
}
.i-help {
  color: #eb8c31;
  font-size: 1.5rem;
}
.card-invoice-account {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.18);
  border-radius: 8px;
}
.f-bold {
  font-weight: bold;
  font-size: 16px;
}
.f-green {
  color: #039855;
}
.f-icon {
  font-size: 1.5rem;
  margin-right: 0.2rem;
  color: #f04438;
}
ion-text {
  font-size: 13px;
}
ion-icon {
  margin-right: 0.2rem;
}
.vertical-space {
  padding: 0.5rem;
}
</style>
