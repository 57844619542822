<template>
  <div class="mx-5 mt-5 mt-20vh align-center d-flex flex-column">
    <ion-img src="/assets/images/not-found.svg" />
    <ion-label class="text-center fw-600 fs-3 mt-3">{{ $t('no_invoices') }}</ion-label>
    <ion-text class="text-center fw-500 fs-2 mt-2">{{ $t('browse_your_business') }}</ion-text>
    <ion-button class="mt-2" @click="$emit('browseProduct')"> {{ $t('browse_product') }} </ion-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'NotFoundInvoices',
  setup() {
    return {};
  }
});
</script>
<style lang="scss" scoped>
ion-button {
  text-transform: capitalize;
  margin-left: -1px;
  width: 100%;
}
.mt-20vh {
  margin-top: 20vh !important;
}
ion-img {
  width: 50%;
}
</style>
