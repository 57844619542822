<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="$emit('closeModal')"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600 fs-2">{{ $t('payment_history') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-for="(item, idx) in data.details" :key="idx">
        <ion-card class="w-100 my-1 mx-0">
          <ion-card-content class="px-1 py-1">
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-text
                    :class="{
                      'text-primary-orange-500': item.type === 'Payment',
                      'text-primary-green-500': item.type !== 'Payment',
                      'payment-bg': item.type === 'Payment',
                      'credit-bg': item.type !== 'Payment'
                    }"
                    class="fs-2"
                  >
                    <span style="font-size: 10px">&#9679;</span>
                    {{ item.type }}
                  </ion-text>
                </ion-col>
                <ion-col size="auto">
                  <ion-text class="fs-2 fw-bold">{{ item.paymentType || '' }}</ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-text class="fs-2 fw-500">{{
                    dayjs(item.paymentDate).format('ddd, D MMM YYYY HH:mm')
                  }}</ion-text>
                </ion-col>
                <ion-col size="auto">
                  <ion-text class="fs-2 fw-bold">{{ priceFormatter(currencySymbol, item.amount) }}</ion-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-text class="fw-bold fs-2 text-primary-green-500">{{ item.paymentNumber }}</ion-text>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
      </div>
      <ion-card class="w-100 my-1 mx-0">
        <ion-card-content class="px-1 py-1">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-text class="fs-2 fw-bold">{{ $t('total_paid_amount').toUpperCase() }}</ion-text>
              </ion-col>
              <ion-col size="auto">
                <ion-text class="fs-2 fw-bold">{{
                  priceFormatter(currencySymbol, data.paidAmount)
                }}</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-card class="w-100 my-1 mx-0" v-if="data.paymentStatus !== 'paid'">
        <ion-card-content class="px-1 py-1">
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-text class="fs-2 fw-bold">{{ $t('amount_due').toUpperCase() }}</ion-text>
              </ion-col>
              <ion-col size="auto">
                <ion-text class="fs-2 fw-bold text-primary-orange-500">{{
                  priceFormatter(currencySymbol, data.amountDue)
                }}</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <ion-footer>
      <ion-toolbar class="mx-0">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col>
              <ion-row>
                <ion-text class="fw-bold text-gray-600">{{
                  data.amountDue > 0 ? $t('amount_due').toUpperCase() : $t('grand_total').toUpperCase()
                }}</ion-text>
              </ion-row>
            </ion-col>
            <ion-col size="auto" class="ion-text-right">
              <ion-row>
                <ion-text
                  :class="{
                    'text-primary-orange-500': data.paymentStatus !== 'paid',
                    'text-primary-green-500': data.paymentStatus === 'paid'
                  }"
                  class="fw-bold"
                >
                  {{ data.paymentStatus.toUpperCase() }}
                </ion-text>
              </ion-row>
              <ion-row class="ion-text-right">
                <ion-text v-if="data.paymentStatus === 'paid'" class="fs-2 fw-bold">{{
                  priceFormatter(currencySymbol, data.paidAmount)
                }}</ion-text>
                <ion-text v-else class="fw-bold">{{
                  priceFormatter(currencySymbol, data.amountDue)
                }}</ion-text>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { priceFormatter } from '@/utils';
import dayjs from 'dayjs';
import { chevronBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PaymentHistories',
  emits: ['closeModal'],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup() {
    return {
      chevronBackOutline,
      dayjs,
      priceFormatter
    };
  }
});
</script>

<style lang="scss" scoped>
.payment-bg {
  background-color: rgba(236, 148, 5, 0.2);
  border-radius: 5px;
  padding: 2px 5px;
}
.credit-bg {
  background-color: rgba(2, 73, 28, 0.2);
  border-radius: 5px;
  padding: 2px 5px;
}
</style>
